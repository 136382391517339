import { Component, effect, signal } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';
import { OnboardingService } from '../../onboarding-service';
import { OnboardingBankServices } from '@app/models';

@Component({
    selector: 'init-eika-bank-services',
    templateUrl: './eika-bank-services.html',
    styleUrls: ['./eika-bank-services.sass'],
})
export class EikaBankServices {
    theme = theme.theme;
    themes = THEMES;

    useCustomSetup = JSON.parse(sessionStorage.getItem('USE_CUSTOM_BANK_SETUP')) ?? false;

    bankServicesInformation = [
        {
            title: 'Betalinger rett fra regnskapssystemet',
            text: 'Betalinger som registreres og godkjennes i regnskapsprogrammet overføres automatisk til nettbanken for belastning av konto, eller de legger seg i forfallsregisteret.',
        },
        {
            title: 'Bankavstemming',
            text: 'Informasjon om alle banktransaksjoner og saldo inn i regnskapet. Alle transaksjoner fra bankkontoen din sammenlignes automatisk med hva som er ført i regnskapet for å sikre at du ikke mangler inn- eller utbetalinger. I tillegg får du daglig oppdatering av bokført saldo på konto.',
        },
    ];

    state: OnboardingBankServices;

    constructor(public service: OnboardingService) {
        this.state = this.service.state().BankServices;

        if (!this.state) {
            this.state = this.getDefaultState();
            this.onStateChange();
        }
    }

    useStandardChange() {
        sessionStorage.setItem('USE_CUSTOM_BANK_SETUP', this.useCustomSetup.toString());
        setTimeout(() => {
            if (!this.useCustomSetup) {
                this.state = this.getDefaultState();
            }
            this.onStateChange();
        });
    }

    onStateChange() {
        setTimeout(() => {
            this.service.setBankServices(this.state);
            this.service.setCurrentStepIsValid(
                !this.state.HasStatements || (this.state.HasStatements && !!this.state.BankStatementStartDate),
            );
        });
    }

    getDefaultState() {
        const now = new Date();
        const firstOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        return {
            HasPayments: true,
            HasStatements: true,
            BankStatementStartDate: firstOfNextMonth,
            HasRecievables: false,
            HasPreApprovedPayment: true,
        };
    }
}
