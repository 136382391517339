@switch (theme) {
    @case (themes.EIKA) {
        <init-eika-company-created />
    }
    @case (themes.SR) {
        <init-sb1-company-created />
    }
    @default {
        <h1 class="mt-2 mb-2">DEFAULT COMPANY CREATED</h1>
    }
}
