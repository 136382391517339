<h1>Velg bedrift</h1>

<h2>Påloggingen var vellykket!</h2>
@if (!onboardingService.selectCompanyState()?.brregLocked) {
    <p>Legg inn navn eller organisasjonsnummer på din bedrift</p>
}

@if (!onboardingService.state()?.Customer?.OrgNumber) {
    <label class="uni-label label-above mt-4">
        <autocomplete
            [readonly]="false"
            [options]="config"
            [value]="onboardingService.state()?.Customer?.Name"
            (valueChange)="setCustomerAndState($event)"
        >
        </autocomplete>
    </label>
} @else {
    <label class="uni-label label-above mt-4 mb-0">
        <span>Valgt bedrift</span>
        <section class="alert info flex justify-between align-center mb-0">
            <span style="font-weight: 500">
                {{ onboardingService.state()?.Customer?.Name }} - {{ onboardingService.state()?.Customer?.OrgNumber }}
            </span>
            @if (!onboardingService.selectCompanyState()?.brregLocked) {
                <i
                    class="material-icons pointer"
                    style="font-size: 18px"
                    title="Fjern valgt bedrift"
                    (click)="setCustomerAndState(null)"
                >
                    close
                </i>
            }
        </section>
    </label>
}
