@switch (theme) {
    @case (themes.EIKA) {
        <init-eika-default-bank-account />
    }
    @case (themes.SR) {
        <init-sb1-default-bank-account />
    }
    @default {
        <h1 class="mt-2 mb-2">DEFAULT DEFAULT BANK ACCOUNT</h1>
    }
}
