import { Component } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';

@Component({
    selector: 'init-company-created',
    templateUrl: './company-created.html',
    styleUrls: ['./company-created.sass'],
})
export class CompanyCreated {
    theme = theme.theme;
    themes = THEMES;

    constructor() {}
}
