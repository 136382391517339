import { Component } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';

@Component({
    selector: 'init-bank-services',
    templateUrl: './bank-services.html',
    styleUrls: ['./bank-services.sass'],
})
export class BankServices {
    theme = theme.theme;
    themes = THEMES;

    constructor() {}
}
