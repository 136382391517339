<h1>{{ onboardingService.selectCompanyState().viewMode === 2 ? 'Registrer bedrift' : 'Velg bedrift' }}</h1>
@if (onboardingService.busy) {
    <mat-spinner class="c2a"></mat-spinner>
} @else {
    @if (
        onboardingService.selectCompanyState().endUserAgreements?.length &&
        onboardingService.selectCompanyState().viewMode === 1
    ) {
        <p>
            <!-- Different text if only one agreement? -->
            Vi ser at du er tilknyttet flere bedrifter. Velg den du ønsker å registrere.
        </p>

        <section class="end-user-agreements-wrapper">
            <uni-radio-group-deprecated
                class="cards mt-4"
                [(ngModel)]="selectedEndUserAgreementID"
                (ngModelChange)="onAgreementSelected()"
            >
                @for (
                    agreement of onboardingService.selectCompanyState().endUserAgreements;
                    track agreement.AgreementID
                ) {
                    <uni-radio-button-deprecated [value]="agreement.AgreementID">
                        <strong>{{ agreement.OrganizationName }}</strong>
                    </uni-radio-button-deprecated>
                }
            </uni-radio-group-deprecated>
        </section>

        <p>
            Finner du ikke bedriften din, eller skal du registrere for et selskap under stiftelse?
            <a class="eika-special-link ml-1" (click)="changeView()">Oppgi din bedrift her</a>
        </p>
    } @else if (
        !onboardingService.selectCompanyState().endUserAgreements?.length ||
        onboardingService.selectCompanyState().viewMode === 2
    ) {
        @if (!onboardingService.selectCompanyState()?.brregLocked) {
            <p>Oppgi ditt organisasjonsnummer så søker vi opp bedriften i Brønnøysund.</p>
        }

        @if (!onboardingService.state()?.Customer?.OrgNumber) {
            <label class="uni-label label-above mt-4">
                <span>Oppgi ditt organisasjonsnummer</span>
                <autocomplete
                    [readonly]="false"
                    [options]="config"
                    [value]="onboardingService.state()?.Customer?.Name"
                    (valueChange)="setCustomerAndState($event)"
                >
                </autocomplete>
            </label>
        } @else {
            <label class="uni-label label-above mt-4">
                <span>Valgt firma</span>
                <section class="alert info flex justify-between align-center">
                    <span style="font-weight: 500">
                        {{ onboardingService.state()?.Customer?.Name }} -
                        {{ onboardingService.state()?.Customer?.OrgNumber }}
                    </span>
                    @if (!onboardingService.selectCompanyState()?.brregLocked) {
                        <i
                            class="material-icons pointer"
                            style="font-size: 18px"
                            title="Fjern valgt firma"
                            (click)="setCustomerAndState(null)"
                        >
                            close
                        </i>
                    }
                </section>
            </label>
        }

        @if (!onboardingService.selectCompanyState()?.brregLocked) {
            <p class="mt-4 mb-1 flex align-center gap-1">
                <rig-checkbox
                    [(ngModel)]="onboardingService.selectCompanyState().missingOrgNumber"
                    (ngModelChange)="setCustomerAndState(null)"
                >
                    Mangler din bedrift organisasjonsnummer?
                </rig-checkbox>
            </p>
        }

        @if (onboardingService.selectCompanyState().missingOrgNumber) {
            <label class="uni-label label-above">
                <span>Oppgi bedriftens navn her</span>
                <input
                    uniAutoFocus
                    type="text"
                    [required]="onboardingService.selectCompanyState().missingOrgNumber"
                    [(ngModel)]="companyNameMissingOrgNr"
                    (blur)="setCompanyNameMissingOrgNr()"
                />
            </label>
        }

        @if (
            onboardingService.selectCompanyState().endUserAgreements?.length &&
            !onboardingService.selectCompanyState()?.brregLocked
        ) {
            <a class="eika-special-link mt-2" (click)="changeView()">Gå tilbake til bedriftsvalg</a>
        }
    }
}
