@if (!onboardingService.busyCreatingCompany && !onboardingService.isDemoFlow) {
    <h1>Koble kontoer</h1>
    <section style="font-size: 18px">
        Når du nå logger på med BankID vil du få tilgang til alle dine kontoer og banktransaksjoner rett inn i
        bankavstemming.
    </section>

    <section class="sub-heading">Ønsker du å gjennomføre betalinger rett fra DNB Regnskap?</section>

    <section class="mb-3">
        <label class="uni-label">
            <span>Betalinger</span>
            <rig-checkbox [(ngModel)]="paymentsInApp">Ja takk, det vil jeg</rig-checkbox>
        </label>
    </section>

    @if (paymentsInApp) {
        <section class="mb-3">
            <label class="uni-label">
                <span>Godkjenning av betalinger</span>
                <mat-radio-group class="vertical" [(ngModel)]="selectedApprovalLocation">
                    <mat-radio-button [value]="approvalLocation.InApp" class="mb-1">
                        Jeg vil godkjenne i regnskapssystemet
                    </mat-radio-button>

                    <mat-radio-button [value]="approvalLocation.InBank">
                        Jeg vil godkjenne i nettbanken
                    </mat-radio-button>
                </mat-radio-group>
            </label>
        </section>

        <section class="alert warn borderless">
            <span>
                <strong>Viktig ved bruk av regnskapsgodkjente betalinger</strong>
                <br />Den som godkjenner betalinger i regnskapet, må være bruker i den integrerte nettbanken og ha
                rettigheter på kontoene det betales fra.
            </span>
        </section>
    }

    <section class="nav-buttons">
        <button class="secondary" (click)="onboardingService.goBack()">Tilbake</button>

        <button class="c2a" (click)="createCustomer()">Koble kontoer med BankID</button>
    </section>
} @else {
    <section class="busy-state flex-column align-center justify-center">
        <uni-illustration name="pending" />

        <h1 class="flex align-center gap-2">
            <mat-spinner aria-hidden="true" class="c2a" [diameter]="20"></mat-spinner>
            {{ onboardingService.isDemoFlow ? 'Oppretter demobedrift' : 'Registrerer foretak' }}
        </h1>

        @if (onboardingService.isDemoFlow) {
            <p>
                Vi setter nå opp en demobedrift i programmet med alle innstillinger og data du trenger for å komme i
                gang. Det kan ta litt tid, men så fort alt er klart til bruk blir du logget på. Du vil også motta en
                e-post når demobedriften er klar til bruk.
            </p>
        } @else {
            <p>
                Vi setter nå opp systemet med innstillinger og data du trenger for å komme i gang. Denne prosessen kan
                ta litt tid.
                <br /><br />Du vil bli logget på selskapet så snart det er klart til bruk.
            </p>
        }
    </section>
}
