@switch (theme) {
    @default {
        <h1 class="justify-center mb-1">Vi kunne ikke finne rettighetene dine på selskapet</h1>
        <section class="mb-1">
            Det kan se ut som om du mangler rettigheter til {{ companyName || 'selskapet' }} i Brønnøysund eller i
            banken.
        </section>

        <section class="mb-1">
            <strong style="font-size: 18px">Følgende alternativer finnes</strong>
        </section>

        <section class="mb-1">
            For å fortsette aktiveringen av selskapet i {{ appName }} kan du gjøre et av følgende steg:
        </section>

        <section class="mb-1">
            <ul class="validation-options">
                @for (validationText of validationErrorTexts; track $index) {
                    <li [innerHTML]="validationText"></li>
                }
            </ul>
        </section>

        @if (isDnb) {
            <section style="margin-bottom: -1rem">
                Du kan lese mer om rettigheter
                <a
                    href="https://hjelp.dnbregnskap.dnb.no/no/article/rettigheter-til-a-etablere-selskap-i-dnb-regnskap-1054guj/"
                    target="_blank"
                >
                    her
                </a>
            </section>
        }

        <section class="nav-buttons" style="justify-content: start">
            <button class="secondary" (click)="backToStart()">Tilbake til selskapsvelger</button>
        </section>
    }
}
