@switch (theme) {
    @default {
        <h1>Merverdiavgift</h1>

        <label class="uni-label mt-3">
            <span style="font-size: 16px">Skal selskapet betale merverdiavgift?</span>
            <uni-radio-group-deprecated [(ngModel)]="selectedType" (change)="taxTypeSelected()" class="vertical">
                <uni-radio-button-deprecated [value]="taxType.TaxMandatory">Ja</uni-radio-button-deprecated>
                <uni-radio-button-deprecated [value]="taxType.NotTaxMandatory">Nei</uni-radio-button-deprecated>
                <uni-radio-button-deprecated [value]="taxType.FutureTaxMandatory"
                    >Planlegger over 50.000 i mva-pliktig omsetning innenfor 12 måneder</uni-radio-button-deprecated
                >
            </uni-radio-group-deprecated>
        </label>
    }
}
