<section class="bankaccounts-table">
    <table [class.editable]="editable">
        <thead>
            <tr class="header-row">
                <th class="left-side">Bankkonto</th>
                <th class="right-side">
                    Hovedbokskonto
                    @if (editable) {
                        <span>(forslag)</span>
                    }
                </th>
            </tr>
        </thead>
        <tbody>
            @for (account of bankAccounts; track account.AccountNumber; let i = $index) {
                <tr class="account-row">
                    <td [class.valign-top]="editAccountRowIndex === i && errorMessage?.length">
                        {{ account.Label }}
                        @if (account.AccountNumber) {
                            <p>{{ account.AccountNumber | uninumberformat: 'bankacct' }}</p>
                        }
                    </td>
                    <td>
                        @if (editAccountRowIndex !== i) {
                            <span>
                                {{ account.Account?.AccountNumber || 'Ikke satt' }}
                                @if (editable && editAccountRowIndex < 0) {
                                    <a class="edit-button" (click)="editLedgerAccountNumber(account, i)"> Endre </a>
                                }
                            </span>
                        }

                        @if (editAccountRowIndex === i) {
                            <div>
                                <input
                                    class="ledger-account-input"
                                    type="number"
                                    name="accN{{ i }}"
                                    id="accId{{ i }}"
                                    [(ngModel)]="ledgerAccountNumberInput"
                                    uniAutoFocus
                                    (keyup.enter)="setGeneralLedgerAccount(account)"
                                />
                                <a (click)="setGeneralLedgerAccount(account)">Lagre</a>
                                @if (errorMessage?.length) {
                                    <p class="ledger-account-error">
                                        {{ errorMessage }}
                                    </p>
                                }
                            </div>
                        }
                    </td>
                </tr>
            }
        </tbody>
    </table>
</section>
