@if (!onboardingService.busyCreatingCompany) {
    <h1>{{ onboardingService.isDemoFlow ? 'Kontaktinformasjon' : 'Sjekk at informasjonen stemmer' }}</h1>

    <h2>Bedrift</h2>
    @if (onboardingService.isDemoFlow) {
        <p>Demobedrift</p>
    } @else {
        <p class="mb-4">
            {{ onboardingService.state()?.Customer?.Name
            }}{{ onboardingService.state()?.Customer?.OrgNumber ? ',' : '' }}
            @if (onboardingService.state()?.Customer?.OrgNumber) {
                <span style="display: inline-block">
                    Org.nr {{ onboardingService.state()?.Customer?.OrgNumber | uninumberformat: 'orgno' }}
                </span>
            }
        </p>
    }

    <form [formGroup]="contactForm">
        <label class="uni-label">
            <span class="flex">
                E-postadresse til bedriften
                <uni-icon-deprecated
                    icon="help_circle"
                    svgSize="22"
                    class="ml-1"
                    style="cursor: pointer"
                    (click)="showEmailTooltip = !showEmailTooltip"
                />
            </span>
            @if (showEmailTooltip) {
                <p class="tooltip-text">
                    E-postadressen legges inn som firmaopplysning i regnskapsprogrammet, og vil være synlig på tilbud,
                    ordrer og fakturaer du lager.
                </p>
            }
            <input type="text" formControlName="ContactEmail" />
            @if (contactForm.controls['ContactEmail'].touched && !contactForm.controls['ContactEmail'].valid) {
                <small class="bad">
                    {{ contactForm.controls['ContactEmail'].value ? 'Ugyldig e-postadresse' : 'Feltet må fylles ut' }}
                </small>
            }
        </label>

        <label class="uni-label">
            <span class="flex">
                Telefonnummer til bedriften
                <uni-icon-deprecated
                    icon="help_circle"
                    svgSize="22"
                    class="ml-1"
                    style="cursor: pointer"
                    (click)="showPhoneTooltip = !showPhoneTooltip"
                />
            </span>
            @if (showPhoneTooltip) {
                <p class="tooltip-text">
                    Telefonnummeret legges inn som firmaopplysning i regnskapsprogrammet, og vil være synlig på tilbud,
                    ordrer og fakturaer du lager.
                </p>
            }
            <input type="text" formControlName="ContactPhone" />
            @if (contactForm.controls['ContactPhone'].touched && !contactForm.controls['ContactPhone'].valid) {
                <small class="bad"> Feltet må fylles ut </small>
            }
        </label>

        <label class="uni-label">
            <span>Kontaktperson</span>
            <input type="text" formControlName="ContactPerson" />
            @if (contactForm.controls['ContactPerson'].touched && !contactForm.controls['ContactPerson'].valid) {
                <small class="bad"> Feltet må fylles ut </small>
            }
        </label>
    </form>

    @if (!onboardingService.isDemoFlow) {
        <h2 class="mt-4">Tjenester</h2>

        <section class="selected-bank-service">
            <svg fill="none" width="22" viewBox="0 0 24 24">
                <path
                    d="m5.5 13 4 5.4c.3.3.7.3.9 0l8.4-12.8"
                    stroke="#008A00"
                    stroke-width="2.5"
                    stroke-linecap="round"
                />
            </svg>
            <span>Regnskapsprogram: SpareBank 1 Regnskap</span>
        </section>

        @if (onboardingService.state()?.BankServices?.HasPayments) {
            <section class="selected-bank-service">
                <svg fill="none" width="22" viewBox="0 0 24 24">
                    <path
                        d="m5.5 13 4 5.4c.3.3.7.3.9 0l8.4-12.8"
                        stroke="#008A00"
                        stroke-width="2.5"
                        stroke-linecap="round"
                    />
                </svg>
                <span>
                    {{
                        onboardingService.state().BankServices?.HasPreApprovedPayment
                            ? 'Du kan betale og godkjenne betalinger fra regnskapsprogrammet'
                            : 'Du kan betale fra regnskapsprogrammet, men betalingene må ettergodkjennes i nettbanken'
                    }}
                </span>
            </section>
        }

        @if (onboardingService.state()?.BankServices?.HasStatements) {
            <section class="selected-bank-service">
                <svg fill="none" width="22" viewBox="0 0 24 24">
                    <path
                        d="m5.5 13 4 5.4c.3.3.7.3.9 0l8.4-12.8"
                        stroke="#008A00"
                        stroke-width="2.5"
                        stroke-linecap="round"
                    />
                </svg>
                <span>
                    Informasjon om inn- og utbetalinger sendes fra banken til regnskapsprogrammet, oppstart for
                    banksavstemming:
                    {{ onboardingService.state()?.BankServices?.BankStatementStartDate | date: 'dd.MM.yyyy' }}
                </span>
            </section>
        }

        @if (onboardingService.bankAccountsState().bankAccounts?.length) {
            <section class="mt-4">
                @if (!hasVisitedBankAccountsStep) {
                    <p>
                        Vi har har laget et forslag til kobling mellom bankkonto og hovedbokskonto,
                        <br />for {{ onboardingService.state()?.Customer?.Name }}.
                        <a (click)="goToBankAccountsStep()">Klikk her for å se eller endre.</a>
                    </p>
                } @else {
                    <h2>Bankkontoer og kobling mot hovedbokskonto</h2>
                    <init-bank-accounts-table
                        [bankAccounts]="onboardingService.bankAccountsState().bankAccounts"
                        [editable]="false"
                    />
                }
            </section>
        }
    }

    <section class="nav-buttons">
        @if (onboardingService.isDemoFlow) {
            <button class="c2a" (click)="createDemoCustomer()">Bekreft og fortsett til demo</button>
        } @else {
            <button class="secondary" (click)="onboardingService.goBack()">Tilbake</button>

            <button class="c2a" (click)="createCustomer()">Aktiver kundeforhold</button>
        }
    </section>
} @else {
    <section class="busy-state flex-column align-center justify-center">
        <uni-illustration name="pending" />

        <h1 class="flex align-center gap-2">
            <mat-spinner aria-hidden="true" class="c2a" [diameter]="20"></mat-spinner>
            {{ onboardingService.isDemoFlow ? 'Oppretter demobedrift' : 'Registrerer foretak' }}
        </h1>

        @if (onboardingService.isDemoFlow) {
            <p>
                Vi setter nå opp en demobedrift i programmet med alle innstillinger og data du trenger for å komme i
                gang. Det kan ta litt tid, men så fort alt er klart til bruk blir du logget på. Du vil også motta en
                e-post når demobedriften er klar til bruk.
            </p>
        } @else {
            <p>
                Vi kobler sammen bank og regnskap og setter opp systemet med alle innstillinger og data du trenger for å
                komme i gang. Det kan ta litt tid, men så fort alt er klart til bruk blir du logget&nbsp;på.
            </p>
        }
    </section>
}
