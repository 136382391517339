@if (onboardingService.busy) {
    <mat-spinner class="c2a"></mat-spinner>
} @else {
    @if (state().endUserAgreements.length > 0 && state().viewMode === 1) {
        <h1>Velg bedrift</h1>

        @if (state().endUserAgreements.length > 1) {
            <p>Vi ser at du er tilknyttet flere bedrifter. Velg den du ønsker å registrere.</p>
        }

        <section class="end-user-agreements-wrapper">
            <uni-radio-group-deprecated
                [(ngModel)]="selectedEndUserAgreementID"
                (ngModelChange)="onAgreementSelected()"
                class="cards mt-4"
            >
                @for (agreement of state().endUserAgreements; track agreement.AgreementID) {
                    <uni-radio-button-deprecated [value]="agreement.AgreementID">
                        <strong>{{ agreement.OrganizationName }}</strong>
                    </uni-radio-button-deprecated>
                }
            </uni-radio-group-deprecated>
        </section>

        <p class="mt-4">
            Finner du ikke bedriften?
            <button class="link" (click)="changeView()">Registrer den her</button>
        </p>
    } @else {
        <h1>Registrer bedrift</h1>
        @if (!state()?.brregLocked) {
            <p>Oppgi ditt organisasjonsnummer så søker vi opp bedriften i Brønnøysundregistrene.</p>
        }

        @if (!onboardingService.state()?.Customer?.OrgNumber) {
            <label class="uni-label label-above mt-4">
                <span>Oppgi ditt organisasjonsnummer</span>
                <autocomplete
                    [readonly]="false"
                    [options]="config"
                    [value]="onboardingService.state()?.Customer?.Name"
                    (valueChange)="setCompanyData($event)"
                >
                </autocomplete>
            </label>
        } @else {
            <p class="mt-4">Valgt firma</p>

            <section class="selected-company flex align-center justify-between border rounded">
                <span
                    >{{ onboardingService.state()?.Customer?.Name }} -
                    {{ onboardingService.state()?.Customer?.OrgNumber }}</span
                >
                @if (!state()?.brregLocked) {
                    <button class="icon-button small" aria-label="Fjern valgt firma" (click)="setCompanyData(null)">
                        <i class="material-icons" style="font-size: 18px">close</i>
                    </button>
                }
            </section>
        }

        @if (state().endUserAgreements.length && !state()?.brregLocked) {
            <p>
                <button class="link" (click)="changeView()">Gå tilbake til bedriftsvalg</button>
            </p>
        }
    }
}
