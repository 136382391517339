import { Component } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';

@Component({
    selector: 'init-default-bank-account',
    templateUrl: './default-bank-account.html',
    styleUrls: ['./default-bank-account.sass'],
})
export class DefaultBankAccount {
    theme = theme.theme;
    themes = THEMES;

    constructor() {}
}
