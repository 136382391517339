<section class="contract-types" *ngIf="onboardingService?.contractTypes?.length">
    <section class="contract-type-grid">
        <contract-type-card
            *ngFor="let contractType of onboardingService.contractTypes"
            buttonClass="{{ isSb1 && isContractActivation ? 'c2a' : '' }}"
            [contractType]="contractType"
            [recommended]="contractType.Name === 'Plus' && (isDnb || isEika)"
            (selected)="onContractTypeSelected(contractType.ContractType)"
        >
        </contract-type-card>
    </section>

    <contract-types-comparison [contractTypes]="onboardingService.contractTypes" [useExt02SignupTheme]="isDnb" />
</section>
