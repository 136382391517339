@if (!editMode) {
    <h1>{{ 'ONBOARDING.BANKID_STEP2_HEADING' | translate }}</h1>
} @else {
    <h1>Rediger informasjon</h1>
}

<section class="sub-heading">
    {{ 'ONBOARDING.BANKID_STEP2_SUB_HEADING' | translate }}
</section>

<section class="info-box">
    @if (!editMode && !onboardingService.selectCompanyState()?.brregLocked) {
        <button class="edit-info tertiary c2a" (click)="editMode = true">Rediger</button>
    }

    <form [formGroup]="companyInfoForm">
        <section class="info-field">
            <strong>{{ 'ONBOARDING.COMPANY_NAME' | translate }}:</strong>
            @if (!editMode) {
                <span>{{ companyInfoForm.value.CompanyName }}</span>
            } @else {
                <input
                    type="text"
                    formControlName="CompanyName"
                    [readonly]="
                        onboardingService.selectCompanyState()?.brregLocked && onboardingService.state()?.Customer?.Name
                    "
                />
            }
        </section>

        <section class="info-field">
            <strong>{{ 'ONBOARDING.ORG_NUMBER' | translate }}:</strong>
            @if (!editMode) {
                <span>{{ companyInfoForm.value.OrganizationNumber }}</span>
            } @else {
                <input
                    type="text"
                    formControlName="OrganizationNumber"
                    [readonly]="
                        onboardingService.selectCompanyState()?.brregLocked &&
                        onboardingService.state()?.Customer?.OrgNumber
                    "
                />
            }
        </section>

        <section class="info-field">
            <strong>{{ 'ONBOARDING.ADDRESS' | translate }}:</strong>
            @if (!editMode) {
                <span>
                    {{ companyInfoForm.value.Address }}, {{ companyInfoForm.value.PostalCode }}
                    {{ companyInfoForm.value.City }}
                </span>
            } @else {
                <input type="text" formControlName="Address" />
            }
        </section>

        @if (editMode) {
            <section class="info-field">
                <strong>{{ 'ONBOARDING.POSTAL_CODE' | translate }}:</strong>
                <input type="text" formControlName="PostalCode" />
            </section>

            <section class="info-field">
                <strong>{{ 'ONBOARDING.CITY' | translate }}:</strong>
                <input type="text" formControlName="City" />
            </section>
        }
    </form>
</section>

<section class="info-box mb-2">
    @if (!editMode && !onboardingService.selectCompanyState()?.brregLocked) {
        <button class="edit-info tertiary c2a" (click)="editMode = true">Rediger</button>
    }

    <form [formGroup]="contactForm">
        <section class="info-field">
            <strong>{{ 'ONBOARDING.CONTACT_PERSON' | translate }}:</strong>
            @if (!editMode) {
                <span>{{ contactForm.value.ContactPerson }}</span>
            } @else {
                <input type="text" formControlName="ContactPerson" />
            }
        </section>

        <section class="info-field">
            <strong>{{ 'ONBOARDING.EMAIL' | translate }}:</strong>
            @if (!editMode) {
                <span>{{ contactForm.value.ContactEmail }}</span>
            } @else {
                <input type="text" formControlName="ContactEmail" />
            }
        </section>
    </form>
</section>

<!-- Shouldn't hide taxType since the user is allowed to freely edit the company info -->
<label class="uni-label">
    <span style="font-size: 16px; margin-bottom: 8px">Skal selskapet betale moms?</span>
    <uni-radio-group-deprecated [(ngModel)]="selectedTaxType" (change)="taxTypeSelected()" class="vertical">
        <uni-radio-button-deprecated [value]="taxType.TaxMandatory">Ja</uni-radio-button-deprecated>
        <uni-radio-button-deprecated [value]="taxType.NotTaxMandatory">Nei</uni-radio-button-deprecated>
        <uni-radio-button-deprecated [value]="taxType.FutureTaxMandatory">
            Planlegger over 50.000 i mva-pliktig omsetning innenfor 12 måneder
        </uni-radio-button-deprecated>
    </uni-radio-group-deprecated>
</label>
