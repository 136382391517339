<h1>Standardkonto for utbetaling</h1>
@if (onboardingService.busy) {
    <mat-spinner class="c2a"></mat-spinner>
} @else {
    @if (onboardingService.bankAccountsState()?.companyBankAccounts?.length) {
        <p>Kontoen du velger vil være standardkonto for betalinger fra regnskapsprogrammet.</p>

        <label class="uni-label label-above mt-4">
            <span style="font-size: 16px">Standardkonto for utbetaling (driftskonto)</span>
            <uni-select
                [items]="onboardingService.bankAccountsState()?.companyBankAccounts"
                [config]="standardAccountSelectConfig"
                [value]="onboardingService.bankAccountsState()?.selectedDefaultBankAccount"
                (valueChange)="selectDefaultBankAccount($event)"
            >
            </uni-select>
            <section class="account-info" *ngIf="onboardingService.bankAccountsState()?.selectedDefaultBankAccount">
                <span
                    >Kontonummer:&nbsp;&nbsp;{{
                        onboardingService.bankAccountsState()?.selectedDefaultBankAccount?.AccountNumber
                            | uninumberformat: 'bankacct'
                    }}</span
                >
                <span
                    >Saldo:&nbsp;&nbsp;{{
                        onboardingService.bankAccountsState()?.selectedDefaultBankAccount?.BankAccountSettings
                            ?.BalanceAvailable | uninumberformat: 'money' : true
                    }}
                    NOK</span
                >
            </section>
        </label>
    } @else {
        <p>
            Vi kunne ikke finne at du har fullmakter i bedriftens konto. Du kan likevel fortsette registreringen for å
            bli kunde i Eika Regnskap.
        </p>
        <p>Fullmaktsinnehaver kan legge til standardkonto for betaling senere.</p>

        <h2>Sender du mange fakturaer?</h2>
        <p>
            Dersom du sender mange fakturaer og ønsker faktura med KID-nummer (OCR) så kan dette bestilles i banken av
            fullmaktsinnehaver.
        </p>
    }
}
