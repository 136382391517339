import { Component } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';
import { OnboardingService } from '../onboarding-service';
import { CelebrusService } from '@app/services/analytics-services/celebrus-service';
import { COMPANY_CREATION_STEPS } from '@app/components/common/company-creation-wizard/company-creation-steps.enum';

@Component({
    selector: 'init-select-contracttype',
    templateUrl: './select-contracttype.html',
    styleUrls: ['./select-contracttype.sass'],
})
export class SelectContractType {
    isSb1 = theme.theme === THEMES.SR;
    isDnb = theme.theme === THEMES.EXT02;
    isEika = theme.theme === THEMES.EIKA;

    constructor(
        public onboardingService: OnboardingService,
        private celebrusService: CelebrusService,
    ) {}

    ngOnInit() {
        if (this.isDnb) {
            this.celebrusService.logCompanyCreationStep(COMPANY_CREATION_STEPS.CONTRACT_TYPE);
        }
    }

    onContractTypeSelected(contractType: number) {
        this.onboardingService.setSelectedContractType(contractType);
    }
}
