import { Component } from '@angular/core';
import { ONBOARDING_STEPS } from '@app/models';
import { OnboardingService } from '../../onboarding-service';

@Component({
    selector: 'init-sb1-existing-reconciliation',
    templateUrl: './sb1-existing-reconciliation.html',
    styleUrls: ['./sb1-existing-reconciliation.sass'],
})
export class Sb1ExistingReconciliation {
    hasExistingReconciliation: boolean;

    constructor(public onboardingService: OnboardingService) {}

    ngOnInit() {
        if (this.onboardingService.state()?.Extras?.hasExistingReconciliation !== undefined) {
            this.hasExistingReconciliation = this.onboardingService.state()?.Extras?.hasExistingReconciliation;
            this.onboardingService.setCurrentStepIsValid(true);
        }
    }

    onSelectionChange() {
        this.onboardingService.setExtras({
            ...this.onboardingService.state().Extras,
            hasExistingReconciliation: this.hasExistingReconciliation,
        });
        this.onboardingService.setCurrentStepIsValid(true);
        this.onboardingService.setStepIsValidByType(ONBOARDING_STEPS.BANK_ACCOUNTS, !this.hasExistingReconciliation);
    }
}
