@switch (theme) {
    @case (themes.EIKA) {
        <init-eika-bank-services />
    }
    @case (themes.SR) {
        <init-sb1-bank-services />
    }
    @case (themes.EXT02) {
        <init-dnb-bank-services />
    }
    @default {
        <h1 class="mt-2 mb-2">DEFAULT BANK SERVICES</h1>
    }
}
