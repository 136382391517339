import { Component } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';
import { OnboardingService } from '../onboarding-service';

@Component({
    selector: 'init-bank-accounts',
    templateUrl: './bank-accounts.html',
    styleUrls: ['./bank-accounts.sass'],
})
export class InitBankAccounts {
    theme = theme.theme;
    themes = THEMES;

    constructor(public onboardingService: OnboardingService) {}
}
