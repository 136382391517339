<h1>Standardkonto for utbetaling</h1>
@if (onboardingService.busy) {
    <mat-spinner class="c2a"></mat-spinner>
} @else {
    @if (onboardingService.bankAccountsState()?.companyBankAccounts?.length) {
        <p>Kontoen du velger her vil bli foreslått når du skal betale fra regnskapsprogrammet.</p>

        <label class="uni-label label-above mt-4">
            <span style="font-size: 16px">Standardkonto for utbetaling (standard driftskonto)</span>
            <uni-select
                [items]="onboardingService.bankAccountsState()?.companyBankAccounts"
                [config]="standardAccountSelectConfig"
                [value]="onboardingService.bankAccountsState()?.selectedDefaultBankAccount"
                (valueChange)="selectDefaultBankAccount($event)"
            >
            </uni-select>
            @if (onboardingService.bankAccountsState()?.selectedDefaultBankAccount) {
                <section class="account-info">
                    <span>
                        Kontonummer:&nbsp;&nbsp;{{
                            onboardingService.bankAccountsState()?.selectedDefaultBankAccount?.AccountNumber
                                | uninumberformat: 'bankacct'
                        }}
                    </span>
                    <span
                        >Saldo:&nbsp;&nbsp;{{
                            onboardingService.bankAccountsState()?.selectedDefaultBankAccount?.BankAccountSettings
                                ?.BalanceAvailable | uninumberformat: 'money' : true
                        }}
                        NOK</span
                    >
                </section>
            }
        </label>
    } @else {
        <p>
            Vi klarte ikke å finne bankkontoene tilknyttet din avtale. Du kan likevel fortsette registreringen for å bli
            kunde i SpareBank 1 Regnskap.
        </p>
        <p>Administrator kan legge til standardkonto for utbetaling i programmet senere.</p>
    }
}
