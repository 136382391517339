import { Component } from '@angular/core';
import { OnboardingService } from '../../onboarding-service';
import { AuthService } from '@app/authService';
import { forkJoin, of, switchMap } from 'rxjs';
import { OnboardingBankService } from '@app/services/bank/onboardingBankService';
import { InitService } from '@app/services/common/initService';
import { rigDate } from '@app/components/common/utils/rig-date';
import { CompanyService } from '@app/services/common/companyService';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BankConnectOrderIntegrationResponse, ONBOARDING_STEPS } from '@app/models';
import { BankAccountService } from '@app/services/accounting/bankAccountService';
import { ErrorService } from '@app/services/common/errorService';

@Component({
    selector: 'init-sb1-confirm-and-create',
    templateUrl: './sb1-confirm-and-create.html',
    styleUrls: ['./sb1-confirm-and-create.sass'],
})
export class Sb1ConfirmAndCreate {
    contactForm = new UntypedFormGroup(
        {
            ContactEmail: new UntypedFormControl('', [Validators.email, Validators.required]),
            ContactPhone: new UntypedFormControl('', Validators.required),
            ContactPerson: new UntypedFormControl('', Validators.required),
        },
        { updateOn: 'blur' },
    );

    showEmailTooltip = false;
    showPhoneTooltip = false;

    hasVisitedBankAccountsStep = false;

    companyKey: string;

    constructor(
        public onboardingService: OnboardingService,
        public authService: AuthService,
        private onboardingBankService: OnboardingBankService,
        private initService: InitService,
        private companyService: CompanyService,
        private bankAccountService: BankAccountService,
        private errorService: ErrorService,
    ) {}

    ngOnInit() {
        this.loadContactInfo();
        this.contactForm.valueChanges.subscribe(() => this.setContactInfo());
        this.hasVisitedBankAccountsStep = sessionStorage.getItem('HAS_VISITED_BANK_ACCOUNTS') === 'true';
    }

    loadContactInfo() {
        const customer = this.onboardingService.state()?.Customer;
        if (customer?.ContactPerson && customer?.ContactEmail) {
            // && customer?.ContactPhone
            this.contactForm.patchValue({
                ContactEmail: customer.ContactEmail,
                ContactPhone: customer.ContactPhone || '',
                ContactPerson: customer.ContactPerson,
            });
            this.contactForm.markAllAsTouched();
            return;
        }

        this.initService.getCurrentUserInfo().subscribe({
            next: (res) => {
                if (res) {
                    let phoneNumber = res.authPhoneNumber;
                    // split on hyphen if it includes country codes, i.e '47-13371337'
                    if (phoneNumber?.includes('-')) {
                        phoneNumber = phoneNumber.split('-')[1];
                    }

                    this.contactForm.patchValue({
                        ContactEmail: res.email,
                        ContactPhone: phoneNumber || '',
                        ContactPerson: res.fullName || res.userName,
                    });
                    this.contactForm.markAllAsTouched();
                    this.setContactInfo();
                } else {
                    this.setContactInfoBasedOnToken();
                }
            },
            error: (err) => {
                this.setContactInfoBasedOnToken();
            },
        });
    }

    setContactInfoBasedOnToken() {
        const token = this.authService.decodedToken();
        this.contactForm.patchValue({
            ContactEmail: token.email,
            ContactPhone: '',
            ContactPerson: token.name,
        });
        this.setContactInfo();
    }

    setContactInfo() {
        if (!this.contactForm.valid) {
            this.onboardingService.setCurrentStepIsValid(false);
            return;
        }

        const form = this.contactForm.value;
        this.onboardingService.setCustomer({
            ...(this.onboardingService.state()?.Customer ?? {}),
            ContactEmail: form.ContactEmail,
            ContactPhone: form.ContactPhone,
            ContactPerson: form.ContactPerson,
        });

        this.onboardingService.setCurrentStepIsValid(true);
    }

    goToBankAccountsStep() {
        this.onboardingService.setCurrentStepByType(ONBOARDING_STEPS.BANK_ACCOUNTS);
    }

    createCustomer() {
        if (this.onboardingService.state()?.Customer) {
            this.onboardingService.state().Customer.SignUpReferrer = 'SpareBank 1 - Hurtigkjøpsløype';
        }
        this.onboardingService.createCustomer().subscribe({
            next: () => this.onCustomerCreated(),
            error: (err) => {
                this.onboardingService.busyCreatingCompany = false;
                const errorMessageLower = this.errorService.extractMessage(err)?.toLowerCase();
                if (errorMessageLower.includes('user has insufficient access to register company')) {
                    this.onboardingService.setCurrentStepByType(ONBOARDING_STEPS.VALIDATION_ERROR);
                }
            },
        });
    }

    private async onCustomerCreated() {
        const isContractActivation = window.location.href.includes('contract-activation');
        let company;
        try {
            company = await this.onboardingService.checkCompanyCreationStatus().toPromise();
            this.companyKey = company.Key;
            await this.acceptLicenseAgreements();
            await this.onboardingService.sendCompanyCreatedReceipt(this.companyKey).toPromise();

            if (this.onboardingService.hasSelectedEndUserAgreement()) {
                const connectBankResponse = await this.connectBank();
                this.handleConnectBankResponse(connectBankResponse);
                await this.createCompanyBankAccounts();
                await this.createBankAgreement();
            }
        } catch (e) {}

        if (isContractActivation) {
            const body = {
                ID: this.authService.currentUser.License.Company.ID,
                EndDate: rigDate(),
                CompanyKey: this.authService.currentUser.License.Company.Key,
                OrgNumber: null,
                CompanyName: this.authService.currentUser.License.Company.Name,
            };

            const goToNewCompany = () => {
                this.companyService.invalidateCache();
                this.onboardingService.clearSessionStorage();
                this.authService.setActiveCompany(company, '/');
            };

            this.onboardingService.deactivateDemoCompany(body.ID, body).subscribe({
                next: () => {
                    goToNewCompany();
                },
                error: () => {
                    goToNewCompany();
                },
            });
        } else {
            this.onboardingService.busyCreatingCompany = false;
            this.onboardingService.goNext();
        }
    }

    acceptLicenseAgreements() {
        return forkJoin([
            this.initService.acceptUserAgreement(this.companyKey),
            this.initService.acceptCustomerAgreement(this.companyKey),
        ]).toPromise();
    }

    connectBank() {
        const bankIdentifier =
            this.onboardingService.selectCompanyState()?.selectedEndUserAgreement?.bankIdentifier || null;
        const state = this.onboardingService.state();
        return this.onboardingBankService
            .connectBank(
                this.companyKey,
                state.Customer.OrgNumber,
                bankIdentifier,
                null,
                state.BankServices.HasPayments,
                state.BankServices.HasPreApprovedPayment,
                state.BankServices.HasStatements,
            )
            .toPromise();
    }

    createBankAgreement() {
        const state = this.onboardingService.state();
        return this.onboardingBankService
            .createBankAgreement(
                state.Customer.ContactPhone,
                this.companyKey,
                state.BankServices.HasPayments,
                state.BankServices.HasPreApprovedPayment,
                state.BankServices.HasStatements,
                state.BankServices.BankStatementStartDate,
            )
            .toPromise();
    }

    createCompanyBankAccounts() {
        const payload = this.onboardingService.getCompanyBankAccountsPayload();
        if (!payload.length) return;
        return this.bankAccountService.createCompanyBankAccounts(payload, this.companyKey).toPromise();
    }

    createDemoCustomer() {
        this.initService
            .getTemplates()
            .pipe(
                switchMap((templates) => {
                    let template = templates.find((t) => t.IsTest);
                    return of(template);
                }),
                switchMap((template) => {
                    const payload = this.onboardingService.getDemoCustomerPayload(
                        this.authService.decodedToken(),
                        template?.Key ?? null,
                    );
                    payload.SignUpReferrer = 'SpareBank 1 - Demo';
                    return this.onboardingService.createDemoCustomer(payload);
                }),
            )
            .subscribe(async () => {
                const company = await this.onboardingService.checkCompanyCreationStatus().toPromise();
                await this.initService.acceptUserAgreement(company.Key).toPromise();

                this.onboardingService.clearSessionStorage();
                this.onboardingService.busyCreatingCompany = false;
                this.authService.setActiveCompany(company, '/');
            });
    }

    handleConnectBankResponse(response: BankConnectOrderIntegrationResponse) {
        if (response?.AwaitingManualProcessing) {
            sessionStorage.setItem(`onboardingAwaitingManualProcessing-${this.companyKey}`, 'true');
        }
    }
}
