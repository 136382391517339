import { Component } from '@angular/core';
import { OnboardingService } from '../../onboarding-service';
import { AuthService } from '@app/authService';
import { forkJoin, of, switchMap } from 'rxjs';
import { InitService } from '@app/services/common/initService';
import { rigDate } from '@app/components/common/utils/rig-date';
import { CompanyService } from '@app/services/common/companyService';
import { ApprovalLocation, ONBOARDING_STEPS } from '@app/models';
import { BrunoOnboardingService } from '@app/services/bank/BrunoOnboardingService';
import { ErrorService } from '@app/services/common/errorService';
import { CelebrusService } from '@app/services/analytics-services/celebrus-service';
import { COMPANY_CREATION_STEPS } from '@app/components/common/company-creation-wizard/company-creation-steps.enum';

@Component({
    selector: 'init-dnb-bank-services',
    templateUrl: './dnb-bank-services.html',
    styleUrls: ['./dnb-bank-services.sass'],
})
export class DnbBankServices {
    paymentsInApp = true;
    approvalLocation = ApprovalLocation;
    selectedApprovalLocation = ApprovalLocation.InApp;

    companyKey: string;
    orgNumber: string;

    constructor(
        public onboardingService: OnboardingService,
        public authService: AuthService,
        private brunoOnboardingService: BrunoOnboardingService,
        private initService: InitService,
        private companyService: CompanyService,
        private errorService: ErrorService,
        private celebrusService: CelebrusService,
    ) {}

    ngOnInit() {
        this.celebrusService.logCompanyCreationStep(COMPANY_CREATION_STEPS.BANKID_STEP3);
        if (this.onboardingService.isDemoFlow) {
            this.createDemoCustomer();
        }
    }

    createCustomer() {
        this.onboardingService.createCustomer().subscribe({
            next: () => this.onCustomerCreated(),
            error: (err) => {
                this.onboardingService.busyCreatingCompany = false;
                const errorMessageLower = this.errorService.extractMessage(err)?.toLowerCase();
                if (errorMessageLower.includes('user has insufficient access to register company')) {
                    this.onboardingService.setCurrentStepByType(ONBOARDING_STEPS.VALIDATION_ERROR);
                }
            },
        });
    }

    private async onCustomerCreated() {
        const isContractActivation = window.location.href.includes('contract-activation');
        let company;
        try {
            company = await this.onboardingService.checkCompanyCreationStatus().toPromise();
            this.companyKey = company.Key;
            this.orgNumber = company.OrganizationNumber;
            if (this.paymentsInApp && this.orgNumber) {
                const approvalLocation = this.selectedApprovalLocation === ApprovalLocation.InApp ? 'InApp' : 'InBank';
                localStorage.setItem('bankIdFlow-Approval', `${this.orgNumber},${approvalLocation}`);
            }
            await this.acceptLicenseAgreements();
            await this.createBankAgreement();
        } catch (e) {}

        const goToNewCompany = () => {
            this.celebrusService.logCompanyCreationReceipt();
            this.companyService.invalidateCache();
            this.onboardingService.clearSessionStorage();
            this.authService.setActiveCompany(company, '/');
        };

        if (isContractActivation) {
            const body = {
                ID: this.authService.currentUser.License.Company.ID,
                EndDate: rigDate(),
                CompanyKey: this.authService.currentUser.License.Company.Key,
                OrgNumber: null,
                CompanyName: this.authService.currentUser.License.Company.Name,
            };

            this.onboardingService.deactivateDemoCompany(body.ID, body).subscribe({
                next: () => {
                    goToNewCompany();
                },
                error: () => {
                    goToNewCompany();
                },
            });
        } else {
            this.onboardingService.busyCreatingCompany = false;
            goToNewCompany();
        }
    }

    acceptLicenseAgreements() {
        return forkJoin([
            this.initService.acceptUserAgreement(this.companyKey),
            this.initService.acceptCustomerAgreement(this.companyKey),
        ]).toPromise();
    }

    createBankAgreement() {
        return this.brunoOnboardingService.createAgreementBankID(this.orgNumber, this.companyKey).toPromise();
    }

    createDemoCustomer() {
        this.onboardingService.busyCreatingCompany = true;
        this.initService
            .getTemplates()
            .pipe(
                switchMap((templates) => {
                    let template = templates.find((t) => t.IsTest);
                    return of(template);
                }),
                switchMap((template) => {
                    const payload = this.onboardingService.getDemoCustomerPayload(
                        this.authService.decodedToken(),
                        template?.Key ?? null,
                    );
                    return this.onboardingService.createDemoCustomer(payload);
                }),
            )
            .subscribe(async () => {
                const company = await this.onboardingService.checkCompanyCreationStatus().toPromise();
                await this.initService.acceptUserAgreement(company.Key).toPromise();

                this.celebrusService.logCompanyCreationReceipt();
                this.onboardingService.clearSessionStorage();
                this.onboardingService.busyCreatingCompany = false;
                this.authService.setActiveCompany(company, '/');
            });
    }
}
