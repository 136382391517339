import { Component } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';

@Component({
    selector: 'init-existing-reconciliation',
    templateUrl: './existing-reconciliation.html',
    styleUrls: ['./existing-reconciliation.sass'],
})
export class ExistingReconciliation {
    theme = theme.theme;
    themes = THEMES;

    constructor() {}
}
