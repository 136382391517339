<h1>Kobling mot hovedbokskonto</h1>

<p>
    Vi har laget et forslag til kobling mellom bankkonto og hovedbokskonto i regnskapet. Se over at koblingen blir
    riktig for {{ onboardingService.state().Customer.Name }}. Trykk "endre" dersom du ønsker å koble en bankkonto til en
    annen hovedbokskonto. <br /><br />
    <strong>Merk:</strong> Om dere ønsker å endre på denne koblingen senere, så kan dere gjøre dette direkte i
    regnskapsprogrammet.
</p>

<init-bank-accounts-table
    [bankAccounts]="onboardingService.bankAccountsState()?.bankAccounts"
    [editable]="true"
    [(editAccountRowIndex)]="bankAccountsEditRowIndex"
    [(errorMessage)]="bankAccountsErrorMessage"
    [(ledgerAccountNumberInput)]="ledgerAccountNumberInput"
/>

<section class="nav-buttons">
    <button class="secondary" (click)="onboardingService.goBack()">Tilbake</button>

    <button class="c2a" (click)="goNext()">Gå videre</button>
</section>
