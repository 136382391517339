<h1 class="mt-2 mb-2">Du finnes allerede som bruker i {{ appName }}</h1>

<p>Ønsker du å logge inn på eksisterende bedrift, eller registrere en ny bedrift?</p>

<section class="alert info mt-2 mb-2">
    <i class="material-icons">info</i>
    <span>
        Når du registerer en ny bedrift, opprettes det et nytt kundeforhold slik at bedriften får separat faktura.
    </span>
</section>

<section class="nav-buttons mt-4 flex justify-center">
    <button class="secondary" (click)="next(false)">Logg på eksisterende</button>

    <button class="c2a" (click)="next(true)">Opprett nytt kundeforhold</button>
</section>
