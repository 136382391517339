@if (!onboardingService.busyCreatingCompany) {
    <h1>{{ onboardingService.isDemoFlow ? 'Kontaktinformasjon' : 'Sjekk at dine opplysninger stemmer' }}</h1>

    <h2>Foretak</h2>
    @if (onboardingService.isDemoFlow) {
        <p>Demobedrift</p>
    } @else {
        <p class="mb-4">
            {{ onboardingService.state()?.Customer?.Name
            }}{{ onboardingService.state()?.Customer?.OrgNumber ? ',' : '' }}
            <span style="display: inline-block" *ngIf="onboardingService.state()?.Customer?.OrgNumber">
                Org.nr {{ onboardingService.state()?.Customer?.OrgNumber | uninumberformat: 'orgno' }}
            </span>
        </p>
    }

    <h2>Ditt navn</h2>
    <p class="mb-2">{{ onboardingService.state()?.Customer?.ContactPerson }}</p>
    @if (!onboardingService.isDemoFlow) {
        <section class="alert info mb-4">
            <i class="material-icons-outlined">info</i>
            <span>Du vil få administratorrettigheter og kan legge til flere brukere senere</span>
        </section>
    }

    <h2 class="mt-4">Din e-postadresse</h2>
    <p class="mb-4">{{ onboardingService.state()?.Customer?.ContactEmail }}</p>

    <h2>Ditt mobiltelefonnummer</h2>
    <p class="mb-4">{{ onboardingService.state()?.Customer?.ContactPhone || 'Ikke satt' }}</p>

    @if (!onboardingService.isDemoFlow) {
        <h1 class="mt-4" style="font-size: 20px">Valgte tjenester</h1>

        <section class="selected-bank-service">
            <i class="material-icons color-good">task_alt</i>
            <span>Regnskapsprogram fra Eika Regnskap</span>
        </section>

        <section class="selected-bank-service" *ngIf="onboardingService.state()?.BankServices?.HasPayments">
            <i class="material-icons color-good">task_alt</i>
            <span>
                {{
                    onboardingService.state().BankServices?.HasPreApprovedPayment
                        ? 'Du kan betale og godkjenne betalinger fra regnskapsprogrammet'
                        : 'Du kan betale fra regnskapsprogrammet, men betalingene må ettergodkjennes i nettbanken'
                }}
            </span>
        </section>

        <section class="selected-bank-service" *ngIf="onboardingService.state()?.BankServices?.HasStatements">
            <i class="material-icons color-good">task_alt</i>
            <span>
                Informasjon om inn- og utbetalinger sendes fra banken til regnskapsprogrammet, oppstart for
                banksavstemming:
                {{ onboardingService.state()?.BankServices?.BankStatementStartDate | date: 'dd.MM.yyyy' }}
            </span>
        </section>
    }

    <section class="nav-buttons">
        @if (onboardingService.isDemoFlow) {
            <button class="c2a" (click)="createDemoCustomer()">Bekreft og fortsett til demo</button>
        } @else {
            <button class="secondary" (click)="onboardingService.goBack()">Tilbake</button>

            <button class="c2a" (click)="createCustomer()">Bekreft og opprett bedrift</button>
        }
    </section>
} @else {
    <section class="busy-state flex-column align-center justify-center">
        <uni-illustration name="pending" />

        <h1 class="flex align-center gap-2">
            <mat-spinner aria-hidden="true" class="c2a" [diameter]="20"></mat-spinner>
            {{ onboardingService.isDemoFlow ? 'Oppretter demobedrift' : 'Registrerer foretak' }}
        </h1>

        @if (onboardingService.isDemoFlow) {
            <p>
                Vi setter nå opp en demobedrift i programmet med alle innstillinger og data du trenger for å komme i
                gang. Det kan ta litt tid, men så fort alt er klart til bruk blir du logget på. Du vil også motta en
                e-post når demobedriften er klar til bruk.
            </p>
        } @else {
            <p>
                Vi kobler sammen bank og regnskap og setter opp systemet med alle innstillinger og data du trenger for å
                komme i gang. Det kan ta litt tid, men så fort alt er klart til bruk blir du logget&nbsp;på.
            </p>
        }
    </section>
}
