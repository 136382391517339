<h1>Har {{ onboardingService.state().Customer.Name }} allerede et regnskap?</h1>

<label class="uni-label mt-3">
    <span> Har det blitt ført regnskap for {{ onboardingService.state().Customer.Name }} tidligere? </span>
    <uni-radio-group-deprecated [(ngModel)]="hasExistingReconciliation" (change)="onSelectionChange()" class="vertical">
        <uni-radio-button-deprecated [value]="false">
            Nei, det har ikke blitt ført regnskap for foretaket tidligere
        </uni-radio-button-deprecated>
        <uni-radio-button-deprecated [value]="true">
            Ja, det har blitt ført regnskap for foretaket tidligere
        </uni-radio-button-deprecated>
    </uni-radio-group-deprecated>
</label>
